<template>
    <div class="CommodityList-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">SEO优化</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/industrymarket' }">数据分析</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/commoditylist' }">商品榜单</el-breadcrumb-item>
            <el-breadcrumb-item>{{tabName}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">
            <div>{{tabName}}</div>
            <div class="period-select">
                <el-radio-group v-model="period" size="medium" @change="getData">
                    <el-radio-button v-for="item, index in periodList" :label="index + 1">{{ item }}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="goodsPurchase-container">
            <el-tabs v-model="categoryActive" @tab-click="handleClick" class="commodityTab">
                <el-tab-pane label="品牌榜单" name="brandList">
                    <BrandList :period="period" />
                </el-tab-pane>
                <el-tab-pane label="交易榜单" name="tradingList">
                    <TradingList :period="period" :tabName="tabName" />
                </el-tab-pane>
<!--                <el-tab-pane label="人气榜单" name="popularityList">-->
<!--                    <PopularityList :tabName="tabName" />-->
<!--                </el-tab-pane>-->
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import TradingList from '@/components/student/commodityList/TradingList'
    import PopularityList from '@/components/student/commodityList/PopularityList'
    import BrandList from '@/components/student/commodityList/BrandList'

    export default {
        data() {
            return {
                period: 1,
                periodList: ["第一周期", "第二周期", "第三周期"],
                categoryActive: 'tradingList',
                tabName: '交易榜单',
            }
        },
        components: {
            TradingList,
            PopularityList,
            BrandList
        },
        methods: {
            handleClick(tab, event) {
                // console.log(tab, event);
                if (tab.name === 'tradingList') {
                    this.tabName = '交易榜单'
                } else if (tab.name === 'popularityList') {
                    this.tabName = '人气榜单'
                } else if (tab.name === 'brandList') {
                    this.tabName = '品牌榜单'
                }
            },
            getData() {

            }
        }
    }
</script>

<style scoped lang="scss">
    .page-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 0 20px 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    /deep/ .commodityTab {
        .el-tabs__header {
            margin-bottom: 0;
            .el-tabs__nav-wrap {
                &:after {
                    content: none;
                }
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                color: #666;
                font-weight: 400;
                &.is-active, &:hover {
                    color: #FD4446;
                }
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
        }
    }
</style>
